<template>
  <div class="content_box">
    <div class="content">
      <div class="con_top">
        <div class="left">
          <div class="ignore" @click="toCardList('showCard')">
            <div class="left_box">
              <!--哪个运营商就显示哪个-->
              <img
                v-if="operatorLogo"
                alt="logo"
                :src="operatorLogo"
              />
              <div>
                <img
                  alt="logo"
                  v-if="
                    cardInfo.hasOwnProperty('iccids') &&
                      cardInfo.iccids.length > 1
                  "
                  src="../../assets/image/personal/interactive.png"
                />
              </div>
            </div>
          </div>
          <div class="center">
            <!-- 没有充值卡号 -->
            <div v-if="!Boolean(cardInfo.virtualId)">
              卡号:{{ cardInfo.iccid }}
            </div>
            <!-- 有充值卡号 -->
            <div v-else>卡号:{{ cardInfo.virtualId }}</div>
            <!-- 有apnId且有nickname -->
            <div v-if="Boolean(cardInfo.apnId)">APN:{{ cardInfo.apnId }}</div>
            <div v-if="Boolean(cardInfo.nickname)">
              昵称:{{ cardInfo.nickname }}
            </div>
            <div v-if="cardInfo.totalVoice !== null">{{'手机号' + cardInfo.sim}}</div>
          </div>
        </div>
        <div class="right">
          <!-- <div
            class="img_left"
            @click="toComponent('fb-list')"
            v-if="openWorkOrder == 1"
          >
            <img
              alt="logo"
              src="../../assets/image/personal/icon_personalcenter_service.png"
            />
            <span class="tips_num" v-if="unreadMsgNumber != 0">{{
              unreadMsgNumber
            }}</span>
          </div> -->
          <div class="img_right" @click="toComponent('message-content')">
            <img
              alt="logo"
              src="../../assets/image/personal/icon_personalcenter_msg.png"
            />
            <span class="tips_num" v-if="noticeNum != 0">{{ noticeNum }}</span>
          </div>
        </div>
      </div>
      <div class="con_center">
        <div class="ring" v-if="showFlow">
          <Circleprogress
            backColor="#ffffff"
            :lineColor="lineColor"
            :width="160"
            ref="tutorial"
          ></Circleprogress>
          <div class="ring_box">
            <div class="fraction" v-if="!flowStatus||flowStatus == 'all'">
              <div class="desc_">
                剩余流量{{
                  surplus >= 1024 || -1024 >= surplus ? "(GB)" : "(MB)"
                }}
              </div>
              <div class="text">
                {{
                  surplus >= 1024 || -1024 >= surplus
                    ? (surplus / 1024).toFixed(2)
                    : surplus
                }}
              </div>
            </div>
            <div class="fraction" v-if="flowStatus == 'used'">
              <div class="desc_">
                已用流量{{
                  usedFlow >= 1024 || -1024 >= usedFlow ? "(GB)" : "(MB)"
                }}
              </div>
              <div class="text">
                {{
                  usedFlow >= 1024 || -1024 >= usedFlow
                    ? (usedFlow / 1024).toFixed(2)
                    : usedFlow
                }}
              </div>
            </div>
            <div class="title font-center" @click="toComponent('usage-record')">
              <span>查看详情</span>
            </div>
          </div>
        </div>
        <div class="ring" v-if="showVoice">
          <Circleprogress
            backColor="#ffffff"
            :showVoice="showVoice"
            :lineColor_="lineColor_"
            :width="160"
            ref="tutorial_"
          ></Circleprogress>
          <div class="ring_box">
            <div class="fraction">
              <div class="desc_">剩余语音(分钟)</div>
              <div class="text">{{ voiceSurplus }}</div>
            </div>
            <div class="title font-center" @click="toComponent('usage-record')">
              <span>查看详情</span>
            </div>
          </div>
        </div>
      </div>
      <div class="desc">
        <span> 用量数据有所延迟，具体以运营商的数据为准 </span>
      </div>
			<!-- 实名提示 -->
      <div
        class="con_bottom"
        v-if="
          needRealName_ ||
            needRealName ||
            (isRealName == 2 && realNameUrl !== '')
        "
      >
        <div class="desc_left">
          <img alt="logo" src="../../assets/image/personal/notice.png" />
        </div>
        <div class="desc_center">
          <span class="wenzi">为避免无法正常使用，请您尽快提交实名认证</span>
        </div>
        <!-- <div
          class="desc_right font-center"
          @click="toComponent('personal-authenTication')"
        >
          <span>去实名</span>
        </div> -->
        <div
          class="desc_right font-center"
          @click="checkIsNewRealName(1)"
        >
          <span>去实名</span>
        </div>
      </div>
			<!-- 领取卡片提示 -->
			<van-swipe style="height: 50px;background: rgba(0, 0, 0, 0.2)" vertical  autoplay="2000" :show-indicators="false" :touchable="false" v-if="isShowGetCardAd && !Boolean(isSimple)">
				<van-swipe-item v-for="(item,idx) in 2" :key="idx">
					<div class="receiveCard">
						<div class="left">
							<div class="title">温馨提示</div>
							<div class="tips">{{homeChangeCardText ? homeChangeCardText:'恭喜您获取体验电信极速网络体验资格'}}</div>
						</div>
						<van-button size='small' class="right_receive" @click="clickAdv">立即领取</van-button>
					</div>
				</van-swipe-item>
			</van-swipe>
    </div>
    <div class="options_box">
      <div class="service">
        <div class="left">
          <div
            class="desc"
            :class="{
              colorRed:
                new Date().getTime() + 432000000 >=
                new Date(cardInfo.endTime).getTime()
            }"
          >
            {{ (cardInfo.endTime && !isShowPackageInfoTips) ? cardInfo.endTime.slice(0, 11) : "----" }}
          </div>
          <div class="status">有效期</div>
        </div>
        <div class="line"></div>
        <div class="center">
          <div
            class="desc cardStatus"
            :class="{
              useFull:
                cardInfo.statusCode == 1 ||
                cardInfo.statusCode == 2 ||
                cardInfo.statusCode === 0
            }"
          >
            {{ cardInfo.status ? cardInfo.status : "----" }}
          </div>
          <div class="status">卡片状态</div>
        </div>
        <div class="line"></div>
        <div class="right" @click="getCardRealName">
          <div class="desc">
            {{typeRealName(isRealName)}} <van-icon v-if="isRealName === 0" name="replay" />
          </div>
          <div class="status">实名状态</div>
        </div>
      </div>
      <div class="banner" v-if="bannerList.length>0 && !Boolean(isSimple)">
        <van-swipe class="my-swipe" :autoplay="bannerInfo.intervalStatus ? bannerInfo.timeInterval * 1000 : 0"  indicator-color="white">
          <van-swipe-item v-for="(item,index) in bannerList" :key="index" @click="openUrl(item.url,item.needParams)">
            <img :src="item.imgUrl" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="action">
        <div
          class="left"
          @click="toComponent('personal-packageRecharge', { type: 0 })"
        >
          <div>
            <img
              alt="logo"
              src="../../assets/image/personal/packageRecharge.png"
            />
          </div>
          <div class="desc">
            <span>{{
              cardInfo.totalVoice !== null ? "话费充值" : "套餐充值"
            }}</span>
          </div>
        </div>
        <div class="center"></div>
        <div class="right" @click="judge(cardInfo.statusCode, cardInfo.status)">
          <div>
            <img
              alt="logo"
              src="../../assets/image/personal/IntelligentDiagnosis.png"
            />
          </div>
          <div class="desc">
            <span>智能诊断</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 功能选项 -->
    <ul class="infos">
      <!-- <li @click="toComponent('personal-authenTication')">
        <img alt="logo" src="../../assets/image/personal/realNameAuth.png" />
        <p>实名认证</p>
      </li> -->
      <li @click="checkIsNewRealName(1)">
        <img alt="logo" src="../../assets/image/personal/realNameAuth.png" />        
        <p>实名认证</p>
      </li>
      <li
        @click="
          toComponent('personal-setMeal', {
            cardType: cardInfo.cardType,
            iccid: cardInfo.iccid
          })
        "
      >
        <img alt="logo" src="../../assets/image/personal/billingCenter.png" />
        <p>账单中心</p>
      </li>
      <li @click="toComponent('personal-cardManagement',{ cantRefundBalance: cardInfo.cantRefundBalance })">
        <img alt="logo" src="../../assets/image/personal/cardMessage.png" />
        <p>卡片管理</p>
      </li>
      <li
        @click="toComponent('personal-shortMessage')"
        v-if="cardInfo.showSms == 1 && cardInfo.operator != 4"
      >
        <img alt="logo" src="../../assets/image/personal/sendSms.png" />
        <p>发送短信</p>
      </li>
      <li @click="toComponent('personal-changecard')" v-if="changeCardShow && !Boolean(isSimple)">
        <img alt="logo" src="../../assets/image/personal/changeCard.png" />
        <p>{{isReceiveCard ? '领取卡片': '换卡管理'}}</p>
      </li>
      <li
        @click="toComponent('personal-whiteList')"
        v-if="cardInfo.whitelistStatus == 1 "
      >
        <img alt="logo" src="../../assets/image/personal/whiteList.png" />
        <p>白名单</p>
      </li>
      <li @click="sycWhiteIpFn" v-if="cardInfo.synWhiteList === 2 && cardInfo.apiCode != 3001 ">
        <img alt="logo" src="../../assets/image/personal/sycWhiteIpFn.png" />
        <p>同步白名单</p>
      </li>
      <li @click="toComponent('stop-recharge', { type: 1 })" v-if="cardInfo.operator != 4 && !Boolean(isSimple)">
        <img alt="logo" src="../../assets/image/personal/stopNum.png" />
        <p>停机保号</p>
      </li>
      <li @click="toComponent('netWork-setting')" v-if="cardInfo.operator == 4 && !Boolean(isSimple)">
        <img alt="logo" src="../../assets/image/personal/operator.png" />
        <p>网络设置</p>
      </li>
      <li @click="toComponent('fb-list')"  v-if="openWorkOrder == 1  && !zhongtian && !Boolean(isSimple)">
        <img alt="logo" src="../../assets/image/personal/service1.png" />
        <p>在线客服</p>
      </li>
      <li @click="toUrl(kefu_complaint_URL)" v-if="!zhongtian && !Boolean(isSimple)">
        <img alt="logo" src="../../assets/image/personal/tousu.png" />
        <p>投诉建议</p>
      </li>
      <!-- <li @click="toUrl('http://icon.iot.xiaoyizuji.com/')">
        <img alt="logo" src="../../assets/image/personal/Group47663.png" />
        <p>小亿商城</p>
      </li> -->
      <li v-if="Boolean(signContract) && !Boolean(isSimple)" @click="handleToShop">
        <img alt="logo" src="../../assets/image/personal/xiaoyi_order1.gif" />
        <p>优选商城</p>
      </li>
      <!-- <li @click="handleToOrder" v-if="showZujiOrder && !Boolean(isSimple)">
        <img alt="logo" src="../../assets/image/personal/xiaoyi_order.png" />
        <p>商城订单</p>
      </li> -->
      <li @click="toComponent('couponList')">
        <img alt="logo" src="../../assets/image/coupon/zhongxun-coupon-icon.png" >
        <p>我的券</p>
      </li>
      <!-- <li @click="handleToDistribution" v-if="xyck_agentInfo.isShow" >
        <img alt="logo" src="../../assets/image/personal/xiaoyi_distribution.gif" />
        <p>分销赚钱</p>
      </li>  -->
      <!-- <li
        v-if="cardInfo.isBind == 1"
        @click="toComponent('personal-changeImei')"
      >
        <img alt="logo" src="../../assets/image/personal/bind.png" />
        <p>机卡绑定</p>
      </li> -->
    </ul>

    <!-- 退出按钮 -->
    <div class="exit" @click="exit">退出</div>
    <!-- 未实名认证提示的弹窗  -->
    <van-popup
      v-model:show="needRealName"
      round
      closeable
      @click-overlay="closeRealName"
      @click-close-icon="closeRealName"
      :close-icon="require(`_ASSETS_/image/popup/closePopup.png`)"
    >
      <div class="auth_popup">
        <div class="goauthenticationImg">
          <img
            src="../../assets/image/popup/icon_goauthentication.png"
            alt=""
          />
        </div>
        <div class="goauthenticationTitle">
          尊敬的用户，请您尽快提交实名认证，完善个人资料，否则我们将依法停止通信服务
        </div>
        <div class="tips_desc">
          <div class="tips_content">
            <!-- {{
              activeRealName
                ? `应工信部要求，卡片需要实名，未实名卡片将无法使用。`
                : `应工信部要求，卡片需要实名，未实名卡片将无法使用。 \n ${cardInfo.iccid} \n 请长按卡号进行复制，使用时粘贴即可`
            }} -->
            应工信部要求，卡片需要实名，未实名卡片将无法使用
          </div>
        </div>
        <div class="viewDetail">
          <van-button class="auth-button" type="primary" @click="checkIsNewRealName(2)"
            >去认证</van-button
          >
        </div>
      </div>
    </van-popup>
    <!-- 未读公告提示的弹窗 -->
    <van-popup
      v-model:show="noticeShow"
      round
      closeable
      :close-icon="require(`_ASSETS_/image/popup/closePopup.png`)"
    >
      <div class="notice_popup" v-if="noticeList.length > 0">
        <div class="goauthenticationImg">
          <p class="title">最新通知</p>
          <img src="../../assets/image/popup/broadcast.png" alt="" />
        </div>
        <div class="goauthenticationTitle">{{ noticeList[0].title }}</div>
        <div class="tips_desc">
          <div class="tips_content">{{ noticeList[0].content }}</div>
        </div>
        <div class="viewDetail">
          <van-button
            class="detail-button"
            type="primary"
            @click="toViewDetail(noticeList)"
            >查看详情</van-button
          >
        </div>
      </div>
    </van-popup>
    <!-- 卡片列表面板 -->
    <van-popup v-model:show="show" :position="'bottom'">
      <div class="cardList_content">
        <div class="title_box">
          <div class="cancel" @click="toCardList('closeCard')">取消</div>
          <div class="title">切换卡片</div>
          <div class="replace" @click="toCardList('cardList')">
            切换选择卡片
          </div>
        </div>
        <div class="list_content">
          <div class="list_box">
            <div
              class="lsit_"
              :class="{ active: cardIndex === index }"
              :key="index"
              v-for="(item, index) in cardInfo.iccids"
              @click="selectCard(item, index)"
            >
              <div>卡片名称:</div>
              <div>卡号:{{ item.virtualId || item.iccid }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 新卡需要充值 -->
    <van-popup v-model:show="isRecharge" round>
      <dialog-container
        @show="closeHandle"
        :title="titleTips"
        :xyck_agentInfo="xyck_agentInfo"
      ></dialog-container>
    </van-popup>
    <!-- 生效套餐 -->
    <van-popup v-model:show="isEffectPakage" round>
      <effect-container
        @show="closeHandle_"
        @effectPakage="effectPakage"
        :title="titlePakage"
        :isReceivePackage="isReceivePackage"
      ></effect-container>
    </van-popup>
    <!-- 互电引流广告弹窗 -->
    <ad-dialog
      ref="adDialog"
      v-if="adDialogShow && !Boolean(isSimple)"
      @adDialogClose="adDialogClose"
    ></ad-dialog>
		<!-- 换卡提示弹窗 -->
		<ad-change-card-dialog
			ref="adChangeCardDialog"
			v-if="isTipsGetCardPopup"
		></ad-change-card-dialog>
    <!-- <van-overlay :show="dialogShow && !Boolean(isSimple)" class="dialog-overlay">
      <div class="overlay-content" v-if="dialogInfo.imgUrl">
        <van-icon name="close" class="overlay-icon" @click.stop="dialogShow = false" color="#fff" size="32" />
        <img :src="dialogInfo.imgUrl" class="overlay-img" @click="openUrl(dialogInfo.url,dialogInfo.needParams)" alt="">
      </div>
    </van-overlay> -->
    <van-overlay :show="promptDialogShow " class="dialog-overlay">
      <div class="overlay-content1" >
        <van-icon name="close" class="overlay-icon" @click.stop="promptDialogShow = false" color="#fff" size="32" />
        <img :src="promptInfo.promptImgUrl" v-if="promptInfo.promptImgUrl" class="overlay-img"  alt="">
        <p v-if="promptInfo.promptMsg">{{promptInfo.promptMsg}}</p>
      </div>
    </van-overlay>

    <!-- 优惠券弹窗 -->
    <platfrom-coupon ref="platfromCoupon"></platfrom-coupon>
    <!-- 跳转小程序弹窗 -->   
    <van-popup v-model:show="realNameFlag" round closeable>
        <div class="realNameBox">
            <div class="realNameContent">
                <slot>
                    <div class="title">前往小程序实名</div>
                </slot>
                <slot>
                <div class="choice">
                    <div class="cancel" @click="realNameFlag = false">取消</div>
                    <div class="confirm">                        
                        <launch-btn v-if="realNameFlag && cardInfo.iccid" :btnText="'去实名'" :iccid="cardInfo.iccid.substring(0,19)"></launch-btn>
                    </div>                   
                </div>
                </slot>
            </div>
        </div>
    </van-popup>
  </div>
</template>

<script>
// 需要哪些模块按需引入
import { toRefs, reactive, onMounted, getCurrentInstance, computed } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import {
  queryCardInfo,
  qryCardRealName,
  queryCardStatus,
  checkExchangeCardTime,
  diagnosis,
  GetEffectPackage,
  queryCardReal,
  realNameUrl,
  synWhiteList,
  NoticeNum,
  NoticeList,
  msgNoticeInfo,
  getUnreadTicketMessage,
  advertLog,
  zyAdBannerList,
  zyAdBannerInfo,
  getDialogCoupon,
  remindCoupon,
  getconf,
  testCard,
  pageNoShowIccidFetchRealUrl
} from "_API_/api.services";
import {
  baseUrl,
  wxAuthUrl,
  getShopInfo,
  queryPurchaseRecord,
  agentShow,
  getUserMobile
} from "_API_/zuJiApi";
import { setStore, getStore, removeStore } from "@/utils/store";
import { isDevice } from "@/utils/utils";
import jssdk from '@/assets/js/jssdk.js'
import { Dialog } from 'vant'
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  setup() {
    // 个人觉着还是这样写舒服一点 类似于vue2中的data
    const state = reactive({  //是否展示代理商分销入口
      xyck_agentInfo:{
        isShow: false,
        platformCode: null,
        iccid: null,
        iotPhone:'',
      },
      isSimple: 0, // 0 常规版 、 1 简版
      platformCode: "", //平台code 码
      signContract: 0, // 是否展示小亿商城  0 不展示  1 展示
      surplus: "", //剩余流量
      usedFlow:'', // 已用流量
      voiceSurplus: "", // 剩余语音
      cardInfo: { iccid: "", status: "", iccids: [] }, //卡号信息
      isRealName: "", //获取实名状态  参数说明:0-否 1-全部实名 2-系统实名 3-三方系统实名 4-暂不实名
      noticeShow: false, //未读公告提示的弹窗展示
      unreadMsgNumber: 0, //未读客服回复数量
      noticeNum: 0, //未读公告数量
      noticeList: [], //未读公告数据源
      show: false, //卡片列表面板展示
      cardIndex: "", //选中的卡片列表下标
      isRecharge: false, //充值展示
      isEffectPakage: false, //生效套餐展示
      titleTips: "", //充值标题展示
      titlePakage: "", //生效套餐标题展示
      isReceivePackage: false, // 领取套餐提示
      showFlow: true, //流量环形图展示
      showVoice: false, //语音环形图展示
      lineColor: "#fff", //流量环形图进度条颜色
      lineColor_: "#fff", //语音环形图进度条颜色
      cardUseAble: true, //卡使用状态
      changeCardShow: false, //换卡入口展示
			isShowGetCardAd:false, //领取卡片轮播广告
      homeChangeCardText:"", //领取卡片文案
			isReceiveCard:false, //领取卡片入口
			isTipsGetCardPopup:false, //提示换卡弹窗
      openWorkOrder: 2, //工单入口展示
      shopUrl: "", //商城入口展示
      needRealName: false, //是否需要实名
      needRealName_: false, //遮罩层的实名提示
      realNameUrl: "", //实名跳转链接
      isNewCTBossRealName: '',
      passRealNameUrl:"",//通道配置实名链接地址 
      activeRealName: "", //点击去实名标识
      theme: "", //主题名称
      adDialogShow: "", // 广告弹窗
      judgePackage: true, //智能诊断前置条件，检测套餐是否生效
      kefu_URL: "", //客服URL
      qiWei_kefu: false, //是否企微客服
      kefu_complaint_URL:'',
      flowStatus:'all',   //展示流量： used : 已用 /  all ： 剩余
      zhongtian:false,
      bannerList: [], // 广告数组
      bannerInfo: {}, // 广告参数
      hasxiaoyiOrder: false, // 是否展示弹窗
      showZujiOrder:window.SITE_CONFIG['showZujiOrder'] || false,
      dialogInfo: {}, // 弹窗信息
      dialogShow: false, // 弹窗是否显示
      // 临时弹窗方案
      promptInfo:{},
      promptDialogShow:false,
      isShowPackageInfoTips:false,  // 语音平台不展示到期时间
      realNameFlag: false
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    var u = navigator.userAgent;
    state.zhongtian = u.indexOf('zhongtian') > -1
    const { $tool: tool, $router: router, $store: store, $http: http } = ctx;
    const methods = {        
        handleLaunchBtn () {
            return new Promise((resolve, reject) => {                    
                if (isDevice() == 'wx') {
                    console.log('sdk配置');
                    // 初始化jssdk的config
                    getconf({
                        url: location.href.split('?')[0],
                    }).then(({ data: res }) => {
                        if (res.code == 0 && !res.data.code) {                    
                            jssdk.config(res.data)      
                            resolve(true)                                                   
                        } else {
                            resolve(false)
                        }
                    })
                }
            })                    
        },
      toUrl(url) {
        console.log(url)
        location.href = url
      },
      realLink(){//ct-boss卡实名跳转
        realNameUrl(state.cardInfo.iccid).then(({ data }) => {
            console.log(data)
            if (data.code == 0 && !data.data.passRealNameUrl) {
              location.href = data.data.realNameUrl
            } else if (data.code != 0) {
              tool.toast({
                msg: data.msg
              });
            }
          });
      },
      //去实名
      async toRealName() {
        // 联通
        if(state.cardInfo.operator == 3 && isDevice() == 'wx'){                               
            state.realNameFlag =  await ctx.handleLaunchBtn()
        }
        if(!state.realNameFlag){            
            if(state.cardInfo.newReal == 1 && (state.isRealName == 0 || state.isRealName == 2) && !state.passRealNameUrl){
              //ct-boss卡实名跳转
              methods.realLink()
              return
            }
            if (state.activeRealName) {
              ctx.realnameFn(state.cardInfo);
              return;
            }
            if (state.needRealName && state.needRealName_) {
              router.push({
                name: "personal-authenTication"
              });
              return;
            }
            // 判断isNewCTBossRealName字段 
            if(state.isNewCTBossRealName == 1){            
                return location.href = state.realNameUrl
            }
            location.href = `${state.realNameUrl}?card=${state.cardInfo.iccid}`;
        }
      },
      //点击未实名弹窗的取消图标 && 遮罩层 触发
      closeRealName() {
        state.needRealName_ = true;
      },
      //公告弹窗
      noticePopup() {
        if (state.noticeNum > 0) {
          // 如果是系统实名 且 通道填写了链接 遮罩层要显示 实名弹窗亦要弹出
          if (state.isRealName == 2 && state.realNameUrl) {
            state.needRealName = true;
            state.needRealName_ = true;
            return;
          }
          if (state.noticeList.lenghth > 0) {
            state.noticeShow = true;
          }
        }
      },
      //状态码弹窗
      warnPopup() {
        return new Promise(resolve => {
          let conf = tool.getCardStatusText(state.cardInfo.statusCode); //卡状态配置
          if (conf.router || conf.router_) {
            tool[conf.router ? "confirm" : "alert"](
              {
                title: "提示",
                msg: conf.msg,
                cancelButtonText: conf.cancelButtonText,
                confirmButtonText: conf.confirmButtonText,
								confirmButtonColor:tool.getThemeList(state.theme)
              },
              () => {
                if (
                  (conf.router === "fb-list" || conf.router_ === "fb-list") &&
                  Boolean(state.kefu_URL)
                ) {
                  return ctx.keFuHandle()
                }
                router.push({
                  name: conf.router || conf.router_,
                  query: {
                    card: state.cardInfo.iccid,
                    type: 0
                  }
                });
                resolve(false);
              },
              () => {
                resolve(false);
              }
            );
          } else {
            resolve(false);
          }
        });
      },
      //退出
      exit() {
        store.dispatch("UserLogout");
        removeStore({ name: "tongLianInfo"});
        removeStore({ name: "contractStatus", type: "session" });
        // removeStore({ name: "isSimple", type: "session" });
        removeStore({ name: "userOpenId", type: "session" });
        removeStore({ name: "userBind", type: "session" });
        removeStore({ name: "initAd", type: "session" });
				removeStore({ name: "isShowGetCardAd", type: "session" });
        location.reload();
      },
      typeRealName(realName){
        realName = Number(realName)
        switch (realName) {
          case 0:
            return '未实名'
          case 5:
            return '部分实名'
          default:
            return '已实名'
        }
      },
      // 客服链接跳转
      keFuHandle(){
        // 跳转企微客服
        state.qiWei_kefu && (location.href = state.kefu_URL);
        // 跳转智齿客服
        !state.qiWei_kefu && (location.href = `${
          state.kefu_URL
        }&params=${JSON.stringify({
          iccid: state.cardInfo.iccid
        })}&customer_fields=${JSON.stringify({
          customField1: state.cardInfo.iccid
        })}`);
      },
      /**
       * 此方法是为了避免原有业务被改动，在最外层判断新的逻辑
       * 点击实名前 调用接口判断 1. 是否满足三个通道 2. 是否有手机号（无：跳转手机绑定页面） 3. 实名链接
       * @param {*} type  1 页面入口 2. 弹窗实名
       */
       async checkIsNewRealName (type=1) {
        // 联通
        if(state.cardInfo.operator == 3 && isDevice() == 'wx'){
            state.realNameFlag =  await ctx.handleLaunchBtn()
        }
        if(!state.realNameFlag){
            pageNoShowIccidFetchRealUrl({
              card: state.cardInfo.iccid, 
              backUrl: window.location.origin + '/query?iccid=' + state.cardInfo.iccid 
            }).then(({ data }) => {
              // 接口调用成功
              if (data.code == 0) {
                // 不满足条件走原来的逻辑
                if (!data?.data?.isNewTelecomRealName) {
                  if (type == 1) {
                    // 原页面实名
                    ctx.toComponent('personal-authenTication')
                  } else {
                    // 原弹窗实名
                    ctx.toRealName()
                  }
                // 没手机号去绑定
                } else if (data?.data?.isNeedMobile == 1) {
                  console.log('跳转手机号绑定页面')
                  router.push({
                    name: 'personal-noShowIccidBindMobile',
                    // realNameUrl: data.data.realNameUrl
                    query: {
                    //   virtualId: store.state.user.userInfo.virtualId,
                    //   iccid: store.state.user.userInfo.iccid,
                      card: state.cardInfo.iccid
                    }
                  });
                } else if (data.data.realNameUrl) {
                // 满足上述 直接跳转
                  location.href = data.data.realNameUrl
                } else {
                  tool.toast({
                      msg: '未配置实名链接，请联系客服！'
                  });    
                }
                return
              }
              // 错误提示
              tool.toast({
                  msg: data.msg
              });
            });
        }
      },
      //路由跳转
      toComponent(name, type) {
        if(name == 'personal-authenTication' && state.cardInfo.newReal == 1 && (state.isRealName == 0 || state.isRealName == 2) && !state.passRealNameUrl){
          methods.realLink()
          return
        }
        if (name === "fb-list" && Boolean(state.kefu_URL)) {
          return ctx.keFuHandle()
        }
				if(name === 'personal-changecard'){
					store.dispatch("SetUserInfo", {isPackage: false})
				}
				if(type != ''){
					store.dispatch("SetUserInfo", {isPackage: true})
				}
        router.push({
          name,
          query: type
        });
      },
      //公告弹窗最新通知 =>查看详情
      toViewDetail(noticeList) {
        msgNoticeInfo({
          msgNoticeId: noticeList[0].id,
          card: state.cardInfo.iccid
        }).then(({ data: res }) => {
          router.push({
            name: "message-notice",
            query: noticeList[0]
          });
        });
      },
      //智能诊断=>检测是否有套餐
      judgeSetMeal() {
        let confUsage = tool.getCardStatusUsageText(state.cardInfo.statusCode); //卡状态配置 未实名 有套餐 用量大于等于90% 提醒文本
        let confNewCard = tool.getCardStatusNewCardText(
          state.cardInfo.statusCode
        ); //卡状态配置 未实名 无套餐 为新卡 提醒文本
        //后台配置主动实名或者不实名 则判断是否有套餐 有套餐则判断用量是否大于等于90%

        // 判断语音
        if (
          state.cardInfo.totalVoice !== null &&
          state.cardInfo.totalVoice != 0
        ) {
          var voiceUsage = state.cardInfo.usedVoice / state.cardInfo.totalVoice;
        }
        // 判断流量
        if (
          state.cardInfo.totalFlow !== null &&
          state.cardInfo.totalFlow != 0
        ) {
          var flowUsage = state.cardInfo.usedFlow / state.cardInfo.totalFlow;
        }
        var bothUsage = false;
        // 判断语音 流量 使用均大于等于90%
        if (voiceUsage >= 0.9 && flowUsage >= 0.9) {
          bothUsage = true;
        }
        if (voiceUsage >= 0.9 || bothUsage || flowUsage >= 0.9) {
          tool.confirm(
            {
              title: "提示",
              msg: voiceUsage
                ? confUsage.totalVoiceMsg
                : bothUsage
                ? confUsage.bothMsg
                : confUsage.totalFlowMsg,
              cancelButtonText: confUsage.cancelButtonText,
              confirmButtonText: confUsage.confirmButtonText,
							confirmButtonColor:tool.getThemeList(state.theme)
            },
            () => {
              router.push({
                name: confUsage.rechargeRouter,
                query: {
                  type: 0
                }
              });
            }
          );
        } else if (
          state.cardInfo.totalFlow == 0 ||
          state.cardInfo.totalFlow === null
        ) {
          //后台配置主动实名或者不实名 无套餐
          tool.confirm(
            {
              title: "提示",
              msg: confNewCard.msg_,
              cancelButtonText: confNewCard.cancelButtonText,
              confirmButtonText: confNewCard.confirmButtonText,
							confirmButtonColor:tool.getThemeList(state.theme)
            },
            () => {
              router.push({
                name: confNewCard.router_,
                query: {
                  type: 0
                }
              });
            }
          );
        } else {
          //常规诊断
          router.push({
            name: "intelligent-diagnosis",
            query: {
              usageCode: state.cardInfo.statusCode
            }
          });
        }
      },
      //智能诊断=>常规去诊断
      routineJudge() {
        // 已断网 且 非DCP的
        if (state.cardInfo.statusCode == 13 && state.cardInfo.isDCP == 0) {
          //常规诊断
          router.push({
            name: "intelligent-diagnosis",
            query: {
              usageCode: state.cardInfo.statusCode
            }
          });
          return;
        }
        // 未实名 且 通道设置了强制实名
        if (
          state.cardInfo.isRealName == 0 &&
          state.cardInfo.realNameType == 1
        ) {
          state.needRealName = true;
          return;
        }
        //检测是否有套餐
        ctx.judgeSetMeal();
      },
      //智能诊断=>已断网
      disconnectRealName(type) {
        let confRealName = tool.getCardStatusRealNameText(
          state.cardInfo.statusCode
        ); //卡状态配置 是DCP 且 已经实名 提醒文本
        if (state.cardInfo.statusCode == 13 && type == 0) {
          //常规诊断
          ctx.routineJudge();
        } else if (
          state.cardInfo.statusCode == 13 &&
          type == 1 &&
          state.isRealName != 0
        ) {
          tool.confirm(
            {
              title: "提示",
              msg: confRealName.msg_,
              cancelButtonText: confRealName.cancelButtonText,
              confirmButtonText: confRealName.confirmButtonText,
							confirmButtonColor:tool.getThemeList(state.theme)
            },
            () => {
              router.push({
                name: confRealName.router_,
                query: {
                  type: 0
                }
              });
            }
          );
        } else if (
          state.cardInfo.statusCode == 13 &&
          type == 1 &&
          state.isRealName == 0
        ) {
          /**
           * @param {type} realNameType 无实名 0 强制实名 1 主动实名 2 被动实名 4
           */
          //后台配置强制实名 则弹起实名弹窗
          if (state.cardInfo.realNameType == 1) {
            state.needRealName = true;
            return;
          }
          //后台配置主动实名或者不实名  则检测是否有套餐
          ctx.judgeSetMeal();
        }
      },
      //智能诊断
      judge() {
				// 聚合卡 点击智能诊断 若有未生效套餐 则提示生效
				if(state.cardInfo.operator == 4 && state.judgePackage){
					// 卡诊断
          return ctx.needCharge(state.cardInfo.iccid);
				}

				// 聚合卡 暂不支持智能诊断
				if(state.cardInfo.operator == 4){
					return tool.toast(
              {
                 msg: "该卡暂不支持",
                 duration: 1500
              },
              () => {}
          );
				}
        let cardText = tool.getCardStatusType(state.cardInfo.statusCode); //卡状态类型
        let conf = tool.getCardStatusText(state.cardInfo.statusCode); //卡状态配置 常规提醒文本
        if (conf.toast) {
          tool[conf.toast]({
            msg: cardText + conf.msg_,
            duration: 3000
          });
        } else if (state.cardInfo.statusCode == 14) {
          state.needRealName = true;
          state.needRealName_ = true;
        } else if (
          (state.cardInfo.isDCP == 0 || state.cardInfo.isDCP == 1) &&
          state.cardInfo.statusCode == 13
        ) {
          ctx.disconnectRealName(state.cardInfo.isDCP);
        } else if (state.cardInfo.statusCode == 15) {
          tool.confirm(
            {
              title: "提示",
              msg: conf.msg,
              cancelButtonText: conf.cancelButtonText,
              confirmButtonText: conf.confirmButtonText,
							confirmButtonColor:tool.getThemeList(state.theme)
            },
            () => {
              router.push({
                name: conf.router,
                query: {
                  type: 0
                }
              });
            }
          );
        } else if (!state.isEffectPakage && state.judgePackage) {
          // 卡诊断
          ctx.needCharge(state.cardInfo.iccid);
        } else {
          //常规诊断
          ctx.routineJudge();
        }
      },
      //卡片列表管理
      toCardList(type) {
        switch (type) {
          case "showCard": //点击弹出卡片列表弹窗
            if (
              state.cardInfo.hasOwnProperty("iccids") &&
              state.cardInfo.iccids.length > 1
            ) {
              state.show = true;
              return;
            }
            break;
          case "closeCard": //点击取消卡片列表弹窗
            state.show = false;
            break;
          case "cardList": //点击切换卡片
            ctx.checkoutCard();
            break;
          default:
            break;
        }
      },
      //选中卡片
      selectCard(item, index) {
        state.cardIndex = index; //存储选中的卡片下标
        state.cardInfo.activeCard = item.iccid; //存储选中的iccid
      },
      //切换卡片
      checkoutCard() {
        if (state.cardIndex !== "") {
          // 查询卡号信息
          ctx.getCardInfo(state.cardInfo);
          //查询卡状态
          ctx.getCardStatus(state.cardInfo);
          //获取公告数量
          ctx.getNoticeNum(state.cardInfo.activeCard);
          //获取未读公告列表
          ctx.getNoticeList({
            card: state.cardInfo.activeCard,
            page: 1,
            limit: 30
          });
          //获取未读客服回复数量
          ctx.getServiceNum({
            card: state.cardInfo.activeCard
          });
          //查询是否有通道链接
          ctx.queryRealNameUrl(state.cardInfo.activeCard);

          state.show = false; //关闭卡片列表面板
          state.cardIndex = ""; //清空选中的卡片下标
          state.unreadMsgNumber = 0; //清空未读客服回复数量
          state.noticeNum = 0; //清空公告数量
          return;
        }
        tool.toast(
          {
            msg: "请选择卡片",
            duration: 1500
          },
          () => {}
        );
      },
      // 检测换卡日期
      exchangeCardParams(iccid) {
        return new Promise(resolve => {
          checkExchangeCardTime(iccid).then(({ data: res }) => {
            resolve(res);
          });
        });
      },
      //检测卡=>需要重新充值
      needCharge(iccid) {
        state.changeCardShow = false;
        state.isReceivePackage = false;
        state.needRealName = false;
        diagnosis(iccid,isDevice()).then(async ({ data: res }) => {
					// 未绑定手机号码
					if(res.code == 201){
						tool.confirm(
							{
								title: "绑定手机号码提醒",
								msg: "您的卡片还未绑定手机号码，绑定后可以直接输入手机号码查询卡片",
								cancelButtonText: "取消",
								confirmButtonText: "去绑定",
								confirmButtonColor:tool.getThemeList(state.theme)
							},
							() => {
								router.push({
									name: "personal-bindMobile",
									query: {
										isExchangeCode: res.isExchangeCode || ""
									}
								});
							}
          	);
						return;
					}
          // 正常换卡
          if (res.code == 207) {
            let result = await ctx.exchangeCardParams(iccid);
            if (result.msg === "true") {
              let changeCardData = res.data;
              state.changeCardShow = true;
              if (changeCardData.status == 2) {
                state.cardUseAble = false;
                // 保存换卡数据
                setStore({
                  name: "changeCardData",
                  content: changeCardData,
                  type: "session"
                });
                return;
              }
              // status 1:未申请 2:已申请 3:已发货 4:置换成功 5:驳回 6:待支付 7:支付中 8:支付失败
              let msg,
                virtualId = state.cardInfo.virtualId;
              if (changeCardData.status == 5) {
                msg = `您的卡:${
                  !virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }的换卡申请被驳回,请前往查看`;
              } else if (
                changeCardData.status == 1 ||
                changeCardData.status == 2 || changeCardData.status == 6 || changeCardData.status == 7
              ) {
                msg = `您的卡:${
                  !virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }需要更换卡片,请前往申请换卡`;
              } else if (changeCardData.status == 3) {
                msg = `您的卡:${
                  !virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }换卡申请已通过,快递${changeCardData.expressCompany +
                  changeCardData.expressNo}`;
              }
              tool.alert(
                {
                  msg,
                  title: "重要提示",
                  confirmButtonText: `立即${changeCardData.status == 3 ? '置换' : '申请'}`
                },
                () => {
                  // 保存换卡数据并前往换卡页面
                  setStore({
                    name: "changeCardData",
                    content: changeCardData,
                    type: "session"
                  });
                  router.push({
                    name: "personal-changecard"
                  });
                }
              );
              return;
            }
            state.cardUseAble = false;
            return tool.toast(
              {
                msg: "换卡时间已过期，请联系客服"
              },
              () => {}
            );
          }
					// 提示换卡
					if(res.code == 208){
							if(Boolean(res.appId)){
								store.dispatch("SetUserInfo", {
									isConfigAppid: true,
									configAppid: res.appId,
								})
							}
							let changeCardData = res.data;
              state.changeCardShow = true;
							state.isShowGetCardAd = false
							state.isReceiveCard = true
							//检测生效套餐
            	state.judgePackage = false;
							// 保存换卡数据并前往换卡页面
              setStore({
                name: "changeCardData",
                content: changeCardData,
                type: "session"
              });
							// 保存提示换卡标识
              setStore({
                name: "isShowGetCardAd",
                content: true ,
                type: "session"
              });
							// status 1:未申请 2:已申请 3:已发货 4:置换成功 5:驳回 6:待支付 7:支付中 8:支付失败
							if(changeCardData.status == 1){
								//套餐中心显示换卡广告
								setStore({
									name: `isChangeCardInPackage`,
									content: true,
									type: "session"
								});
                state.homeChangeCardText = res.data.homeChangeCardText
								state.isShowGetCardAd = true //为提示换卡 且 为待申请 则显示轮播换卡广告
								return ctx.changeCardPopup(res.data) //为提示换卡 且 为待申请 则显示领取卡片弹窗
							}
							if (changeCardData.status == 4) {
                state.changeCardShow = false //为提示换卡 且 为置换成功 则不显示领取卡片入口
              }
              let msg,
                virtualId = state.cardInfo.virtualId;
							if(changeCardData.status == 2){
								msg = `您的卡:${
                  !virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }已提交领卡申请,请前往查看`;
							}else if (changeCardData.status == 3) {
								msg = `您的卡:${
                  !virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }领卡申请已通过,快递${changeCardData.expressCompany +
                  changeCardData.expressNo}`;
              }else if (changeCardData.status == 5) {
								msg = `您的卡:${
                  !virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }的领卡申请被驳回,请前往查看`;
              }else if (changeCardData.status == 6 || changeCardData.status == 8) {
								msg = `您的卡:${
                  !virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }的领卡申请有待支付费用,请前往支付`;
              }else if (changeCardData.status == 7) {
								msg = `您的卡:${
                  !virtualId ? "iccid:" + iccid : "充值卡号：" + virtualId
                }的领卡申请运费支付中,请前往查看`;
              }
              tool.confirm(
                {
                  msg,
                  title: "重要提示",
                  confirmButtonText: "立即前往",
									cancelButtonText:"取消",
									confirmButtonColor:tool.getThemeList(state.theme)
                },
                () => {
                  router.push({
                    name: "personal-changecard"
                  });
                }
              );
              return;
					}
          // 换imei
          if (res.code == 301) {
            tool.confirm(
              {
                title: "提示",
                msg: res.msg,
                confirmButtonText: "去解绑",
								confirmButtonColor:tool.getThemeList(state.theme)
              },
              () => {
                router.push({
                  name: "personal-changeImei"
                });
              }
            );
            return;
          }
          // 去充值
          if (res.code == 203) {
            //检测生效套餐
            state.judgePackage = false;
            //停机保号状态不显示充值提示语
            if (state.cardInfo.statusCode == 17) {
              return;
            }
            state.isRecharge = true;
            state.titleTips = res.msg;
            return;
          }
          //去生效套餐
          if (res.code == 205) {
            state.isEffectPakage = true;
            state.titlePakage = res.msg;
            return;
          }
          // 去领取套餐
          if (res.code == 209) {
            state.isReceivePackage = true
            state.isEffectPakage = true;
            state.titlePakage = res.msg;
            return;
          }
					//类型为穿戴
					if(res.code == 2099 || res.code == 2100){
						return ctx.clickChuangDai(res)
					}
          // 异常
          if (res.code == 500) {
            tool.alert({
              title: "提示",
              msg: res.msg
            });
            return;
          }
          if (res.code == 0) {
            //检测生效套餐
            state.judgePackage = false;
            // 查询实名认证跳转
            await methods.queryCardRealFn();
            if (state.needRealName) return;
          }
          let flag = methods.judgeIsWhitelist();
          //判断是否有状态弹窗要展示
          if (flag) {
            await ctx.warnPopup();
            //判断是否要展示公告弹窗
            ctx.noticePopup();
            return;
          }
          return;
        });
      },
      //套餐生效
      packageTakeEffect() {
        GetEffectPackage(state.cardInfo.iccid).then(({ data: res }) => {
          if (res.code == 0) {
            if(state.isReceivePackage){
              tool.toast(
                {
                  msg: "领取套餐成功",
                  duration: 1000,
                },
                ()=> {
                  router.push({
                    name: "personal-packageRecharge",
                    query:{
                      type: 0
                    }
                  });
                }
              );
              return
            }
            tool.toast({
              msg: "生效套餐成功",
              duration: 3000
            });
            ctx.getCardInfo(state.cardInfo);
            return;
          }
          tool.toast({
            msg: res.msg,
            duration: 1500
          });
        });
      },
      //关闭充值弹窗、
      closeHandle(msg) {
        state.isRecharge = msg;
      },
      //关闭生效套餐弹框
      closeHandle_(msg) {
        state.isEffectPakage = msg;
      },
      //立即生效套餐
      effectPakage() {
        state.isEffectPakage = false;
        // 立即生效套餐
        ctx.packageTakeEffect();
      },
      // 实名检测
      queryCardRealFn() {
        /**
         * @param {type} isRealName 0-否 1-全部实名 2-系统实名 3-三方系统实名 4-暂不实名
         */
        return queryCardReal(state.cardInfo.iccid).then(({ data }) => {
          let res = data.hasOwnProperty("data") ? data.data : "";
          if (
            data &&
            data.code == 0 &&
            data.data &&
            data.data.realNameUrl &&
            data.data.isRealName == 0
          ) {
            state.needRealName = true;
            state.realNameUrl = data.data.realNameUrl; //保存实名链接

            return;
          }
          if (
            data &&
            data.code == 0 &&
            data.data &&
            data.data.hasOwnProperty("activeRealName") &&
            data.data.activeRealName == 1 &&
            data.data.isRealName == 0
          ) {
            state.needRealName = true;
            state.activeRealName = data.data.activeRealName; //保存点击实名标识
            return;
          }
          if (data.code !== 0) {
            return tool.toast({
              msg: data.msg
            });
          }
        });
      },
      // 获取实名链接
      realnameFn({ isRealName }) {
        if (Boolean(state.cardInfo.virtualId) && !isRealName) {
          // 跳转到实名中转页
          return router.push({
            name: "personal-authenTication",
            query: {
              card: state.cardInfo.virtualId
            }
          });
        }
        realNameUrl(state.cardInfo.iccid).then(({ data }) => {
          if(res.code == 0 && res.data.passRealNameUrl){
            state.passRealNameUrl = res.data.passRealNameUrl;
          }
          if (data.code == 0 && data.data.realNameUrl) {
            state.needRealName = false;
            tool.alert(
              {
                title: "提示",
                msg: `请记住以下实名卡号 \n ${state.cardInfo.iccid} \n 请长按卡号进行复制，使用时粘贴即可`
              },
              () => {
                location.href = `${data.data.realNameUrl}?card=${state.cardInfo.iccid}`;
              }
            );
            return;
          } else if (data.code != 0) {
            tool.toast({
              msg: data.msg
            });
            return;
          }
        });
      },
      // 白名单检测
      judgeIsWhitelist(type) {
        let timeId = null;
        if (state.cardInfo.isWhitelist == 0 || type == 2100) {
          tool.confirm(
            {
              title: "提示",
              msg: "温馨提示：只有加入白名单的号码才可正常通话使用",
              cancelButtonText: "我知道了",
              confirmButtonText: "立即添加",
							confirmButtonColor:tool.getThemeList(state.theme)
            },
            () => {
              router.push({
                name: "personal-whiteList"
              });
            },
            () => {
              clearInterval(timeId);
            }
          );
          timeId = setTimeout(() => {
            router.push({
              name: "personal-whiteList"
            });
          }, 3000);
          return false;
        }
        return true;
      },
      // 同步白名单
      sycWhiteIpFn() {
        tool.confirm(
          {
            msg: "请确定是否同步白名单？",
            confirmButtonText: "立即同步",
						confirmButtonColor:tool.getThemeList(state.theme)
          },
          () => {
            synWhiteList({
              cards: state.cardInfo.iccid
            }).then(({ data }) => {
              if (data.code != 0) {
                return tool.alert({
                  title: "提示",
                  msg: data.msg
                });
              }
              return tool.alert(
                {
                  title: "提示",
                  msg: "白名单同步成功"
                },
                () => {
                  methods.getCardInfo(store.state.user.userInfo);
                }
              );
            });
          }
        );
      },
        // 检查卡
        checkCard (conf) {    
            return new Promise((resolve) => {                
                testCard({ card: conf.iccid, device: isDevice() }).then(({ data: res}) => {
                    if (res.msg == '该卡不存在') {
                        tool.alert(
                            {
                                title: '提示',
                                msg: res.msg
                            },
                            () => {
                                ctx.exit();
                            }
                        )
                        return resolve(false) 
                    }
                    resolve(true)
                }) 
            })   
        }, 
      //获取查询的卡号数据
      getCardInfo(info) {
        queryCardInfo(info.activeCard || info.iccid, isDevice()).then(({ data: res }) => {
          if (res.code == 0) {
            state.cardInfo = res.data;
            // 是否展示代理商分销入口
            // if(['tianpeng','xiaoming','dev', 'test'].includes(state.platformCode)){
            //   ctx.agentShow(state.cardInfo.iccid,state.cardInfo.cardId)
            // }
            state.isRealName = res.data.isRealName; //获取实名状态
            //获取剩余流量
            state.surplus = res.data.residualFlow;
            state.usedFlow = res.data.usedFlow
            if(res.data.promptInfo && (res.data.promptInfo.promptMsg || res.data.promptInfo.promptImgUrl)){
              state.promptDialogShow = true
              state.promptInfo = res.data.promptInfo
            }
            let flowPercent = 1,
              voicePercent = 1;
            //流量环形图绘制必备参数判断
            switch (res.data.totalFlow) {
              case 0:
              case null:
                let totalFlow = 1;
                flowPercent = 1;
                state.lineColor = ""; //未生效套餐时清空颜色值
                break;
              default:
                flowPercent = res.data.usedFlow / res.data.totalFlow;
                if (flowPercent >= 0.8 && flowPercent < 0.9) {
                  state.lineColor = "yellow";
                }
                if (flowPercent >= 0.9) {
                  state.lineColor = "red";
                }
                if (flowPercent >= 1) {
                  flowPercent = 1;
                }
                break;
            }
            ctx.$nextTick(() => {
              ctx.$refs.tutorial.draw(flowPercent);
            });
            //获取剩余语音
            state.voiceSurplus = res.data.residualVoice;
            //语音环形图绘制必备参数判断
            switch (res.data.totalVoice) {
              case null:
                state.showVoice = false;
                break;
              case 0:
                state.showVoice = true;
                voicePercent = 1;
                state.lineColor_ = ""; //未生效套餐时清空颜色值
                break;
              default:
                state.showVoice = true;
                voicePercent = res.data.usedVoice / res.data.totalVoice;
                if (voicePercent >= 0.8 && voicePercent < 0.9) {
                  state.lineColor_ = "yellow";
                }
                if (voicePercent >= 0.9) {
                  state.lineColor_ = "red";
                }
                if (voicePercent >= 1) {
                  voicePercent = 1;
                }
                break;
            }
            if (state.showVoice) {
              ctx.$nextTick(() => {
                ctx.$refs.tutorial_.draw(voicePercent);
              });
            }
            //存储用户的其它信息到sessionStorage中
            store.dispatch("SetUserInfo", {
              labels: res.data.labels || [],
              iccid: res.data.iccid,
              virtualId: res.data.virtualId,
              aliAppId: res.data.aliAppId,
              cardId: res.data.cardId,
              iccids: res.data.iccids,
              sim: res.data.sim,
              appid: res.data.AppID,
              wxPayAppid: res.data.wxPayAppid, // 通联appid
              apnId: res.data.apnId,
              money: res.data.money,
              userMoney: res.data.userMoney,
              maxWhitelist: res.data.maxWhitelist,
              operator: res.data.operator,
              passagewayType: res.data.passagewayType,
              nickname: res.data.nickname,
              mobile: res.data.mobile,
              totalVoice:
                res.data.totalVoice !== null ? res.data.totalVoice : "",
              apiCode: res.data.apiCode,
              device: isDevice(),
              promptInfo:res.data.promptInfo,
              paymentWxType: res.data.paymentWxType,
              paymentAliType: res.data.paymentAliType
            });
            state.openWorkOrder = res.openWorkOrder;
            //下载app 互电弹窗引导
            // if (res.data.hdUrl && !getStore({ name: "initAd" })) {
            //   state.adDialogShow = true;
            //   setStore({
            //     name: "initAd",
            //     content: {
            //       value: "show"
            //     },
            //     type: "session"
            //   });
            //   ctx.$nextTick(() => {
            //     ctx.$refs["adDialog"].init(hdUrl);
            //   });
            //   return;
            // }
            // 卡诊断
            ctx.needCharge(state.cardInfo.iccid);
          }
        });
      },
      //获取卡实名状态
      getCardRealName() {
        if(state.isRealName !==0){
          return
        }
        qryCardRealName(state.cardInfo.cardId).then(res=>{
          if(res.status === 200){
            state.isRealName = res.data.isRealName
            if(res.data.isRealName !== 0){
              methods.getCardInfo(store.state.user.userInfo);
              Dialog.alert({
                message: '实名认证成功，如果您已充值套餐，已停用卡片预计5分钟内会恢复可用。',
              })
            }
          }
        })
      },
      //获取卡号状态
      getCardStatus(info) {
        queryCardStatus(info.activeCard || info.iccid).then(({ data: res }) => {
          if (res.code == 55555) {
            Object.assign(state.cardInfo, {
              statusCode: res.code
            });
            return;
          }
          if (
            res.code == 55555 ||
            res.data.code == 88 ||
            res.data.code == 7 ||
            res.data.code == 12
          ) {
            state.cardUseAble = false;
          }
          if (res.code == 0) {
            Object.assign(state.cardInfo, {
              status: res.data.status,
              statusCode: res.data.code
            });
            return;
          }
          tool.toast({
            msg: res.msg,
            duration: 1500
          });
        });
      },
      //获取未读公告数
      getNoticeNum(params) {
        NoticeNum(params).then(({ data: res }) => {
          if (res.code == 0 && res.data.unreadCount != 0) {
            state.noticeNum = res.data.unreadCount;
          }
        });
      },
      //获取未读公告列表
      getNoticeList(params) {
        NoticeList(params).then(({ data: res }) => {
          if (res.code == 0) {
            state.noticeList = res.data;
            return;
          }
          tool.toast({
            msg: res.msg,
            duration: 1500
          });
        });
      },
      //获取未读客服回复数量
      getServiceNum(params) {
        getUnreadTicketMessage(params).then(({ data: res }) => {
          if (res.code == 0) {
            state.unreadMsgNumber = res.unreadMsgNumber;
            return;
          }
        });
      },
      //查询是否有通道链接
      queryRealNameUrl(params) {
        realNameUrl(params).then(({ data: res }) => {
          // 若已经三要素实名，有通道链接 则保存通道链接
          if(res.code == 0 && res.data.passRealNameUrl){
            state.passRealNameUrl = res.data.passRealNameUrl;
          }
          if (
            res.code == 0 &&
            (res.data.realNameStatus == 2 || res.data.realNameStatus == 0) &&
            res.data.realNameUrl
          ) {
            state.realNameUrl = res.data.realNameUrl;
            state.isNewCTBossRealName = res.data.isNewCTBossRealName || ''
            return;
          }
        });
      },
      adDialogClose() {
        // 弹窗广告关闭重新获取卡状态数据
        methods.init();
      },
			// 换卡弹窗控制->当天仅弹出一次
			changeCardPopup(data){
				let date = new Date()
				let today =	'当前日期:' + date.getFullYear().toString() + '/' + (date.getMonth()+1).toString() + '/' + date.getDate().toString()
				let currentDay =  getStore({ name: `${state.cardInfo.iccid} - changeCardDialog` })
				if(!currentDay ||  currentDay != today){
					removeStore({ name: `${state.cardInfo.iccid} - changeCardDialog` });
					state.isTipsGetCardPopup = true
					ctx.$nextTick(()=>{
						ctx.$refs['adChangeCardDialog'].init(data)
					})
					setStore({
        		name: `${state.cardInfo.iccid} - changeCardDialog`,
        		content: today
      		});
				}
			},
      async getBannerList(iccid){
        let {data} = await zyAdBannerList(1,iccid)
        if (data.code == 0) {
          const stableSorting = (s1, s2) => {
            if (s1.sort < s2.sort) return -1;
            return 1;
          };
          state.bannerList = data.data.sort(stableSorting);
        } else{
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        }
      },
      async getBannerDialog(iccid){
        let {data} = await zyAdBannerList(2,iccid)
        if (data.code == 0) {
          const stableSorting = (s1, s2) => {
            if (s1.sort < s2.sort) return -1;
            return 1;
          };
          if (data.data.length > 0) {
            state.dialogInfo = data.data.sort(stableSorting)[0];
            state.dialogShow = true;
          }
        } else{
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        }
      },
      async getBannerInfo(){
        let {data} = await zyAdBannerInfo(1)
        if (data.code == 0) {
          state.bannerInfo = data.data
        } else{
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        }
      },
      // 广告跳转
      openUrl(url,needParams){
        // 若有跳转地址 且 需要携带参数 则拼接字段
        if(Boolean(url) && Number(needParams) == 1){
          location.href = url + `&channelSource=${state.platformCode}&cardNo=${state.cardInfo.iccid}`
        }
        // 若有跳转地址 且 不需要携带参数 则直接跳转
        if(Boolean(url) && Number(needParams) == 0){
          location.href = url
        }
      },
      async init() {
        state.isShowPackageInfoTips = window.SITE_CONFIG['isShowPackageInfoTips'] || false
        //获取客服URL
        state.kefu_URL = window.SITE_CONFIG.kefu_URL;
        // 是否企微客服
        state.qiWei_kefu = window.SITE_CONFIG.qiWei_kefu || false;
        //获取用户信息
        const info = store.state.user.userInfo;
        const isContinueRequest = await ctx.checkCard(info);        
        if(!isContinueRequest) return;
        ctx.getCardInfo(info);
        //查询卡状态
        ctx.getCardStatus(info);
        //获取公告数量
        ctx.getNoticeNum(info.iccid);
        //获取未读公告列表
        ctx.getNoticeList({
          card: info.iccid,
          page: 1,
          limit: 30
        });
        //获取未读客服回复数量
        ctx.getServiceNum({
          card: info.iccid
        });
        //查询是否有通道链接
        ctx.queryRealNameUrl(info.iccid);
        // 获取小亿优选入口展示
        // ctx.getShopInfoHandle(info.iccid)
        // 是否展示 弹窗
        ctx.queryPurchaseRecordHandle(info.iccid)
        // console.log(info)
        //获取商城入口展示
        state.shopUrl = getStore({ name: "shopUrl" }) || ""; //需咨询
        // 查询广告
        ctx.getBannerList(info.iccid)
        ctx.getBannerDialog(info.iccid)
        ctx.getBannerInfo()

        getDialogCoupon({iccid: info.iccid}).then(({data:res})=>{
          if(res.code == 0){
            let list = res.data || [];
            if(list.length > 0){
              ctx.$nextTick(()=>{
                ctx.$refs.platfromCoupon.init(list);
              })
              let couponIds = list.map(v=> v.couponId)
              remindCoupon({iccid: info.iccid,couponIds}).then(({data:res})=>{});
            }
          }
        })

        console.log(getStore({ name: `tempSetMeal` }));
        if(getStore({ name: `tempSetMeal` })){
          removeStore({ name: "tempSetMeal", type: "session"});
          ctx.toComponent('personal-setMeal',{
            cardType: info.cardType,
            iccid: info.iccid,
            isInvoice: true
          })
        }

      },
      clickAdv() {
        advertLog({
          param: store.state.user.userInfo.iccid,
          type:17,
          browserType: store.state.user.userInfo.device
        })
        methods.toComponent('personal-changecard')
      },
			// 穿戴类型
			clickChuangDai(res){
					// 未充值套餐
					if(res.code == 2099){
						tool.confirm(
							{
								title: "提示",
								msg: res.msg,
								cancelButtonText: "取消",
								confirmButtonText: "去充值",
								confirmButtonColor:tool.getThemeList(state.theme)
							},
							() => {
								router.push({
									name: "personal-packageRecharge",
									query:{
										type: 0
									}
								});
							},
            	() => {}
          	);
					}
					// 未添加白名单
					if(res.code == 2100){
						ctx.judgeIsWhitelist(res.code)
					}
			},
      // 获取小亿优选 入口
      // getShopInfoHandle(iccid){
      //   getShopInfo({
      //     iccid,
      //     platformCode : state.platformCode
      //   }).then(({data:res})=>{
      //     if(res.code == 0){
      //       return state.signContract = res.data.signContract
      //     }
      //     state.signContract = 0
      //   })
      // },
      // 订单 大于0 即有购买记录 则 弹窗显示
      queryPurchaseRecordHandle(iccid){
        queryPurchaseRecord({
          cardNo:iccid,
        }).then(({data:res})=>{
          if(res.code == 0 && res.data > 0){
            state.hasxiaoyiOrder = true
          }
        })
      },
      // 跳转小亿订单
      handleToOrder(){
        const concatParams = `channelSource=${state.platformCode}&cardNo=${state.cardInfo.iccid}`
        location.href = `${baseUrl}/pages/orderListPage/orderListPage?${concatParams}`
      },
      // 跳转创客分销
      // handleToDistribution(){
      //   let url = `${wxAuthUrl}/wxAuth/login?type=11&iotPhone=${state.xyck_agentInfo.iotPhone}&platformCode=${state.xyck_agentInfo.platformCode}&iccid=${state.xyck_agentInfo.iccid}`
      //   location.href = url
      // },
      // 跳转小亿优选
      handleToShop(){
        location.href = `${baseUrl}?channelSource=${state.platformCode}&cardNo=${state.cardInfo.iccid}`
      },
      // 是否展示分销入口
      // agentShow(iccid,cardId){
      //   agentShow(iccid).then(({data:res})=>{//拿用户信息
      //     // console.log({res}) 
      //       getUserMobile(cardId).then((re)=>{//拿用户手机号
      //             // console.log(re.data.data)
      //             if(state.platformCode == 'dev'){
      //               state.platformCode = 'zhongxun'
      //             }else if(state.platformCode == 'test'){
      //               state.platformCode = 'xiaoming'
      //             }
      //             if(re.data.code === 0){
      //               let param = {
      //                 isShow:res.isShow,
      //                 platformCode:state.platformCode,
      //                 iccid:iccid,
      //                 iotPhone:re.data.data,
      //               }
      //               state.xyck_agentInfo = param
      //               localStorage.setItem('xyck_agentInfo',JSON.stringify(param))
      //             }
      //       })
      //   })

      // },
    };
    //获取运营商logo 1、电信  2、移动 3、联通 4、聚合卡
    const operatorLogo = computed(() => {
      switch (state.cardInfo.operator) {
        case 1:
          return require('../../assets/image/personal/ChinaTelecom.png')
        case 2:
          return require('../../assets/image/personal/ChinaMobile.png')
        case 3:
          return require('../../assets/image/personal/ChinaUnicom.png')
        case 4:
          return require('../../assets/image/personal/operator4.png')
        default:
          return require('../../assets/image/personal/operator4.png')
      }
    });
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      // 获取平台code 码
      state.platformCode = store.state.common.platformCode;
      state.kefu_complaint_URL = window.SITE_CONFIG['kefu_complaint_URL']
      state.flowStatus = window.SITE_CONFIG['flowStatus'] || 'all'
      state.isSimple = store.state.user.isSimple
      methods.init();
    });
    onBeforeRouteLeave((to, from, next) => {
      if (!state.cardUseAble && to.name == "fb-list") {
        //换卡时间已过 可联系客服
        state.changeCardShow = false;
        next();
      } else if (state.cardInfo.statusCode == 16 && to.name !== "fb-list") {
        tool.toast(
          {
            msg: "超量停机状态不可操作，请联系客服",
            duration: 1500
          },
          () => {}
        );
      } else if (state.cardInfo.statusCode == 55555) {
        tool.toast(
          {
            msg: "注销状态不可操作",
            duration: 1500
          },
          () => {}
        );
      } else if (!state.cardUseAble && to.name !== "personal-changecard") {
        tool.toast(
          {
            msg: "故障卡不可操作",
            duration: 1500
          },
          () => {}
        );
      } else {
        next();
      }
    });
    return { ...toRefs(state), ...methods, operatorLogo };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.content_box {
  display: flex;
  flex-direction: column;
  width: 750px;
  .content {
    width: 750px;
    @include background_color("background_color1");
    .con_top {
      display: flex;
      justify-content: space-between;
      padding: 20px 3% 0;
      .left_box {
        text-align: center;
        position: relative;
        img {
          width: 92px;
          height: 93px;
        }
        div {
          position: absolute;
          bottom: 4px;
          left: 70px;
          img {
            width: 32px;
            height: 33px;
          }
        }
      }
      .center {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 20px;
        div {
          font: {
            size: 30px;
            weight: 400;
          }
          color: #ffffff;
        }
      }
      .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .right {
        display: flex;
        justify-content: flex-end;
        width: 150px;
        align-items: center;
        .img_right {
          margin-left: 15px;
        }
        .img_left,
        .img_right {
          width: 63px;
          height: 64px;
          background: rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          text-align: center;
          line-height: 83px;
          position: relative;
          img {
            width: 63px;
            height: 64px;
          }
          .tips_num {
            font: {
              size: 16px;
              weight: 400;
            }
            width: 38px;
            height: 38px;
            line-height: 38px;
            border-radius: 50%;
            color: #ffffff;
            background: #f34721;
            position: absolute;
            top: -10px;
            left: 36px;
          }
        }
      }
    }
    .con_center {
      display: flex;
      justify-content: space-around;
      .ring {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
      }
      .ring_box {
        width: 100%;
        height: 100%;
        position: absolute;
        text-align: center;
        top: 0;
        left: 0;
        .fraction {
          font-size: 26px;
          font-weight: bold;
          color: #ffffff;
          display: block;
          margin-top: 35%;
          .desc_ {
            font: {
              size: 20px;
              weight: 400;
            }
            color: #ffffff;
            text-align: center;
          }
          .text {
            font: {
              size: 42px;
              weight: bold;
            }
            text-align: center;
            margin-top: 12px;
            color: #ffffff;
          }
        }

        .title {
          width: 138px;
          height: 45px;
          text-align: center;
          border-radius: 22px;
          border: 1px solid rgba(255, 255, 255, 0.8);
          color: #fff;
          font: {
            size: 25px;
            weight: 400;
          }
          position: absolute;
          left: calc(50% - 69px);
          bottom: 15px;
        }
      }
    }
    .desc {
      text-align: center;
      color: #ffffff;
      font: {
        size: 25px;
        weight: 400;
      }
      margin: 40px auto 20px;
    }
    .con_bottom {
      display: flex;
      width: 750px;
      height: 72px;
      background: rgba(0, 0, 0, 0.2);
      align-items: center;
      justify-content: space-evenly;
      .desc_left img {
        width: 40px;
        height: 40px;
      }
      .desc_center {
        width: 520px;
        .wenzi {
          font: {
            size: 25px;
            weight: 400;
          }
          color: #ffffff;
          line-height: 50px;
        }
      }

      .desc_right {
        width: 121px;
        height: 51px;
        background: #ffffff;
        border-radius: 26px;
        font: {
          size: 25px;
          weight: 400;
        }
        @include font_color("font_color1");
      }
    }
		.receiveCard{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 8px 20px;
			.left{
				display: flex;
				flex-direction: column;
				.title{
					color: #ebe7e7;
					font-weight: bold;
				}
				.tips{
					color: #ebe7e7;
					font-size: 26px;
          width: 530px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
				}
			}
			.right_receive {
        width: 150px;
        height: 48px;
        border-radius: 12px;
        border: none;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
        color: red;
      }
		}
  }
  .options_box {
    .service {
      display: flex;
      justify-content: space-evenly;
      height: 133px;
      align-items: center;
      background: #ffffff;
      .left,
      .center,
      .right {
        width: 200px;
        text-align: center;
        .desc {
          font: {
            size: 30px;
            weight: 400;
          }
          color: #333333;
        }
        .colorRed {
          color: #f34721;
        }
        .status {
          font: {
            size: 25px;
            weight: 400;
          }
          text-align: center;
          color: #aaaaaa;
          padding-top: 8px;
        }
      }
      .center {
        .desc {
          color: crimson;
        }
        .useFull {
          color: #5ad8a6;
        }
      }
      .right {
        .desc {
          color: #f6bd16;
        }
      }
      .line {
        width: 1px;
        height: 70px;
        background-color: #f0f0f0;
      }
    }
    .banner {
      padding: 30px;
      background: #fff;
      img {
        width: 688px;
        height: 128px;
      }
    }
    .action {
      display: flex;
      height: 133px;
      justify-content: space-around;
      align-items: center;
      background: #ffffff;
      .left,
      .right {
        display: flex;
        width: 212px;
        height: 83px;
        padding-left: 20px;
        background: linear-gradient(180deg, #ffa25b 0%, #fe6075 100%);
        border-radius: 10px;
        align-items: center;
        justify-content: space-evenly;
        div img {
          width: 52px;
          height: 46px;
        }
        .desc {
          font-size: 29px;
          font-weight: 500;
          color: #ffffff;
          margin-right: 20px;
        }
      }
      .right {
        background: linear-gradient(180deg, #4ca4ff 0%, #8c7bff 100%);
      }
    }
  }
  .infos {
    width: 750px;
    background: #ffffff;
  }
  .infos li {
    display: inline-block;
    width: 187px;
    height: 200px;
    font-size: 20px;
    text-align: center;
    position: relative;
    span.dot {
      position: absolute;
      padding: 2px 3px;
      background: red;
      color: #fff;
      border-radius: 10px;
      right: 15%;
      top: 1%;
      z-index: 2;
    }
  }
  .infos li img {
    margin-bottom: 20px;
    margin-top: 20px;
    width: 93px;
    height: 93px;
  }
  .infos li a {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #565656;
    position: relative;
    p {
      span {
        color: red;
        font-weight: bold;
      }
    }
  }
  .exit {
    width: 690px;
    height: 84px;
    text-align: center;
    line-height: 84px;
    margin: 20px auto 0px;
    color: #fff;
    @include background_color("background_color1");
    box-shadow: 0px 8px 16px 0px #bce1ff;
    border-radius: 20px;
  }
  //实名弹窗样式 && 公告弹窗样式
  .auth_popup,
  .notice_popup {
    width: 590px;
    height: 869px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .goauthenticationImg {
      padding-top: 20px;
      text-align: center;
      img {
        width: 172px;
        height: 170px;
      }
      p {
        font-size: 34px;
        color: #333;
        margin: 10px auto;
      }
    }
    .goauthenticationTitle {
      font: {
        size: 30px;
        weight: 400;
      }
      color: #333333;
      text-align: center;
      padding: 0 26px;
    }
    .tips_desc {
      width: 530px;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 20px;
      border: 2px dashed #aaaaaa;
      .tips_content {
        font: {
          size: 22px;
          weight: 400;
        }
        word-break: break-all;
        color: #aaaaaa;
        padding: 24px;
        text-align: left;
      }
    }
    .viewDetail {
      text-align: center;
      margin-bottom: 20px;
      .auth-button,
      .detail-button {
        @include background_color("background_color1");
        border: none;
      }
    }
  }
  .notice_popup {
    height: auto;
    .tips_desc {
      border: none;
    }
  }

  // 卡片列表面板样式
  .cardList_content {
    width: 100%;
    height: 50vh;
    .title_box {
      height: 100px;
      align-items: center;
      border: 1px solid #f2f2f2;
      display: flex;
      justify-content: space-around;
      .cancel,
      .replace {
        font: {
          size: 32px;
          weight: 400;
        }
        @include font_color("font_color1");
      }
      .cancel {
        padding-right: 40px;
      }
      .title {
        padding-left: 80px;
        font: {
          size: 32px;
          weight: 500;
        }
      }
      .replace {
        padding-left: 30px;
      }
    }
    .list_content {
      height: calc(50vh - 130px);
      overflow-y: auto;
      .list_box {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 30px;
        .lsit_ {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 642px;
          height: 132px;
          background: #f2f2f2;
          border-radius: 20px;
          margin-bottom: 40px;
          padding-left: 48px;
        }
        .active {
          @include background_color("background_color1");
          color: #fff;
        }
      }
    }
  }
}
.van-button--primary {
  width: 530px;
  height: 84px;
  background: #2196f3;
  box-shadow: 0px 8px 16px 0px #bce1ff;
  border-radius: 20px;
}
.font-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .overlay-content,.overlay-content1 {
    position: relative;
    text-align: center;
    .overlay-icon {
      position: absolute;
      right: 60px;
      top: -20px;
    }
    .overlay-img {
      width: 80%;
    }
  }
  .overlay-content1{
    p{
      width: 80%;
      margin: 0 auto;
      background: #fff;
    }
  }

}
.realNameBox {
  display: flex;
  flex-direction: column;
  width: 615px;
  margin: 0 auto;
  height: 300px;
  .appbar {
    // @include border-1px(#eeeeee, bottom);
    border-bottom: 1px solid #eeeeee;
    height: auto;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }
  }
  ::after {
    background: #2196f3 !important;
  }
  .realNameContent {
    flex: 1;
    overflow: auto;
    .title {
      background: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      text-align: center;
      height: 200px;
      line-height: 200px;
    }
    .choice {
      height: 98px;
      background: #ffffff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      text-align: center;
      line-height: 98px;
      justify-content: center;
      .cancel {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: #f2f2f2;
        border-right-style: solid;
        border-right-width: 1px;
        width: 300px;
        color: #aaaaaa;
      }      
      .confirm {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-left-color: #f2f2f2;
        border-left-style: solid;
        border-left-width: 1px;
        width: 300px;
        color: #0bb20c;
      }
    }
  }
}
</style>
